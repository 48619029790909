
<template>
  <div class="py-15">
    <div v-if="showLogin" class="container">
      <h1 class="display-4 text-center mx-auto">Admin auth</h1>
      <p class="lead mx-auto d-block text-center mb-9 mt-4 text-muted">
        You need admin access.
      </p>
      <div class="form-group mx-auto" style="max-width: 400px">
        <form @submit.prevent="signIn()">
          <input
            v-model="email"
            type="email"
            class="faded-input mt-4 form-control"
            name=""
            id=""
            placeholder="Email"
          />
          <input
            v-model="password"
            class="faded-input mt-4 form-control"
            type="password"
            name=""
            id=""
            placeholder="Password"
          />
          <button
            class="btn btn-primary btn-block mt-4"
            :class="{ faded: !email || !password }"
          >
            Sign in
          </button>
        </form>
      </div>
    </div>
    <div v-if="users.length > 0" class="container text-center">
      <h1 class="display-1 mx-auto">{{ users.length }}</h1>
      <p class="lead">Users</p>
      <p>
        <strong>
          {{
            Math.floor(
              (users.filter((user) => user.verified === true).length /
                users.length) *
                100
            )
          }}%
        </strong>
        verified
      </p>
      <button
        v-if="applicants.length === 0"
        @click="getApplicantsCount()"
        class="btn btn-white mt-5"
      >
        Show Applicants
      </button>
      <div v-if="applicants.length > 0">
        <div class="one-liner mx-auto mt-5 mb-5" style="max-width: 230px"></div>
        <h1 class="display-3 mx-auto">{{ applicants.length }}</h1>
        <p class="mb-0 mt-3 text-muted"><b>+ 13 from The Hub</b></p>
        <p class="mb-1 text-muted"><b>+ 29 from Indeed</b></p>
        <p class="mb-1 text-muted">
          <b>= {{ applicants.length + 13 + 29 }} total</b>
        </p>
        <p class="lead">Applicants</p>
      </div>
    </div>
    <h1></h1>
  </div>
</template>

<script>
import { GET, db, firebase } from "@/firebase.js";

export default {
  name: "Stats",
  data: function () {
    return {
      email: null,
      password: null,
      showLogin: false,
      users: [],
      applicants: [],
    };
  },
  methods: {
    signIn: function () {
      firebase.auth().signInWithEmailAndPassword(this.email, this.password);
    },
    signOut: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          this.showLogin = true;
        });
    },
    getUserCount: function () {
      let col = db.collection("users");
      GET(col)
        .then((users) => {
          let userList = users.docs
            .sort((x, y) => x.data().verified - y.data().verified)
            .reverse();
          userList.forEach((user) => {
            console.log(user.data().verified);
            if (
              this.users.find((item) => item.email === user.data().email) ===
              undefined
            ) {
              this.users.push(user.data());
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getApplicantsCount: function () {
      let col = db.collection("applications");
      GET(col).then((applications) => {
        applications.forEach((applications) => {
          if (
            this.applicants.find(
              (item) => item.email === applications.data().email
            ) === undefined
          ) {
            this.applicants.push(applications.data());
          }
        });
      });
    },
    checkIfLoggedIn: function () {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          if (user.uid === "IR5UB3bvT7h7eCcGFpWw7pwmGyD3") {
            this.getUserCount();
            this.showLogin = false;
          } else {
            this.signOut();
          }
        } else {
          this.showLogin = true;
        }
      });
    },
  },
  mounted: function () {
    this.checkIfLoggedIn();
  },
};
</script>

<style>
</style>